import { render, staticRenderFns } from "./karocel.vue?vue&type=template&id=7c0997ca&"
import script from "./karocel.vue?vue&type=script&lang=js&"
export * from "./karocel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports